<template>
  <b-modal
    id="quiz-modal"
    ref="quiz"
    title="Tạo quiz mới"
    @hidden="resetModal"
    @ok="submit"
    style="min-width: 800px"
  >
    <b-container fluid class="p-0">
      <form ref="form-group" @submit="submit">
        <b-row>
          <b-col cols="5">
            <b-row>
              <b-col>
                <basic-input
                  label="Tiêu đề quiz"
                  placeholder="Nhập tiêu đề quiz"
                  name="courseName"
                  :value.sync="filter.name"
                  :required="true"
                ></basic-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <basic-select
                  label="Thuộc bài học"
                  :options="[]"
                  placeholder="LS.001 - Bệnh đái tháo đường là gì, chẩn đoán như thế nào?"
                  name="wards"
                  :value.sync="form.emotionCategoryId"
                  :solid="false"
                  :allowEmpty="false"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <basic-select
                  label="Thuộc khoá học"
                  :options="[]"
                  placeholder="MD.001 - Tổng quan về bệnh đái tháo đường"
                  name="wards"
                  disabled
                  :value.sync="form.emotionCategoryId"
                  :solid="false"
                  :allowEmpty="false"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="7">
            <div class="d-flex justify-content-between align-items-center">
              <h6>Danh sách câu hỏi</h6>
              <b-button @click="handleCreateQuestion">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
                </span>
                Thêm câu hỏi
              </b-button>
            </div>
            <b-row justify="center" class="p-4">
              <b-col>
                <v-expansion-panels accordion id="quiz">
                  <v-expansion-panel v-for="(items, i) in questions" :key="i">
                    <v-expansion-panel-header>
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <p class="font-weight-bolder mb-0">{{ items.title }}</p>
                        <span style="position: relative; z-index: 1">
                          <b-button
                            @click.stop="handleCreateAnswer"
                            class="btn btn-sm btn-success"
                            style="background: #def2f1"
                          >
                            <span class="svg-icon m-0">
                              <inline-svg
                                src="/media/svg/icons/Neolex/Basic/plus.svg"
                              />
                            </span>
                          </b-button>
                          <b-button
                            style="background: #def2f1"
                            class="btn btn-primary btn-sm btn-secondary ml-3"
                          >
                            <span class="svg-icon svg-icon-primary m-0">
                              <inline-svg
                                src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                              />
                            </span>
                          </b-button>
                          <b-button
                            style="background: #def2f1"
                            class="btn btn-danger btn-sm btn-secondary ml-3"
                          >
                            <span class="svg-icon svg-icon-primary m-0">
                              <inline-svg
                                src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                              />
                            </span>
                          </b-button>
                        </span>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :headers="headers"
                        :items="items.contents"
                        hide-default-footer
                        class="elevation-1"
                      >
                        <template v-slot:item.isAnswer="{ item }">
                          <b-checkbox
                            v-if="item.type == 'multi'"
                            v-model="item"
                          ></b-checkbox>
                          <b-form-radio
                            v-else
                            v-model="item"
                            name="some-radios"
                            value="A"
                          ></b-form-radio>
                        </template>
                        <template v-slot:item.action="{ item }">
                          <b-button class="btn btn-primary btn-sm">
                            <span class="svg-icon svg-icon-primary m-0">
                              <inline-svg
                                src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                              />
                            </span>
                          </b-button>
                          <b-button class="btn btn-danger btn-sm ml-3">
                            <span class="svg-icon svg-icon-primary m-0">
                              <inline-svg
                                src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                              />
                            </span>
                          </b-button>
                        </template>
                      </v-data-table>
                      <b-container class="mt-5">
                        <b-row>
                          <b-col>
                            <label for="explaned">Câu giải thích</label>
                            <basic-text-area
                              placeholder="Nhập câu giải thích các đáp án đúng cho câu hỏi"
                              :value.sync="form.note"
                              name="explaned"
                              :state="validationState.note"
                              :invalidFeedback="error.note"
                            />
                          </b-col>
                        </b-row>
                      </b-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </form>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button class="btn btn-success ml-3" type="submit" @click="submit">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </template>
    <answer-modal />
    <quiz-question-modal />
  </b-modal>
</template>

<script>
export default {
  name: 'QuizModal',
  components: {
    'answer-modal': () => import('./AnswerQuestion'),
    'quiz-question-modal': () => import('./QuizQuestion'),
  },
  data() {
    return {
      form: {
        content: '',
      },
      rendered: false,
      validationState: {},
      error: {},
      filter: {},
      headers: [
        {
          text: 'Câu trả lời',
          align: 'left',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Câu đúng',
          align: 'left',
          value: 'isAnswer',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      questions: [
        {
          title: 'Đái tháo đường là bệnh gì?',
          type: 'multi',
          contents: [
            {
              id: 1,
              name: 'Là bệnh mạn tính di truyền',
              isAnswer: false,
              type: 'multi',
            },
            {
              id: 2,
              name: 'Là bệnh rối loạn chuyển hoá mạn tính glucid',
              isAnswer: true,
              type: 'multi',
            },
            {
              id: 3,
              name: 'Là bệnh rối loạn chuyển hoá mạn tính protid',
              isAnswer: true,
              type: 'multi',
            },
            {
              id: 4,
              name: 'Là bệnh rối loạn chuyển hoá mạn tính lipid',
              isAnswer: true,
              type: 'multi',
            },
          ],
        },
        {
          title: 'Nguyên nhân của bệnh đái tháo đường?',
          type: 'single',
          contents: [
            {
              id: 1,
              name: 'Do thiếu insulin hoặc đề kháng insulin hoặc cả hai',
              isAnswer: true,
              type: 'single',
            },
            {
              id: 2,
              name: 'Là bệnh rối loạn chuyển hoá mạn tính glucid',
              isAnswer: false,
              type: 'single',
            },
            {
              id: 3,
              name: 'Do béo phì',
              isAnswer: false,
              type: 'single',
            },
            {
              id: 4,
              name: 'Do gia đình có bệnh lý di truyền',
              isAnswer: false,
              type: 'single',
            },
          ],
        },
      ],
    };
  },

  methods: {
    openStaffPopup() {},
    submit() {},
    resetModal() {},
    handleCreateQuestion() {
      this.$bvModal.show('quiz-question-modal');
    },
    handleCreateAnswer() {
      this.$bvModal.show('answer-modal');
    },
  },
};
</script>

<style lang="scss">
#quiz {
  .v-expansion-panel-header__icon {
    position: absolute;
    left: 0;
  }
}
#quiz-modal {
  .modal-dialog {
    max-width: 80%;
  }
}
.label-required:after {
  content: '*';
  color: red;
}
</style>
<style lang="scss" scoped>
.mh-500 {
  min-height: 500px;
}
.picture-input-container {
  .picture-input {
    .preview-container {
      background: #ffffff;
      border: 3px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 6px;

      .picture-preview {
        background-color: #f3f6f9 !important;
      }

      .picture-inner {
        border: none;
      }
    }
  }
}
</style>
